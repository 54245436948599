import { render, staticRenderFns } from "./nucleicAcid.vue?vue&type=template&id=96498f74&scoped=true&"
import script from "./nucleicAcid.vue?vue&type=script&lang=js&"
export * from "./nucleicAcid.vue?vue&type=script&lang=js&"
import style0 from "./nucleicAcid.vue?vue&type=style&index=0&id=96498f74&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96498f74",
  null
  
)

export default component.exports