<template>
  <div class="body">
    <!-- 核酸检测 -->
    <van-cell
      title="切换就诊人"
      icon="manager-o"
      is-link
      style="text-align: left"
      @click="openChooise()"
    >
      <template #default>
        <span style="color: #999">{{ chooseItem.name }}</span>
      </template>
    </van-cell>
    <van-popup v-model="showPopup" position="bottom" round closeable>
      <div class="yyqr-choose-jzk-over">
        <div class="yyqr-choose-title">选择就诊人</div>
      </div>
      <div
        class="yyqr-choose-jzk"
        v-for="(item, index) in list"
        :key="index"
        @click="chooseJZPerson(item)"
      >
        <span v-if="item.id == personId" class="checked">
          <img src="@/assets/img/checked.png" alt="" />
          {{ item.name + "(" + item.idNumber + ")" }}</span
        >
        <span v-else>{{ item.name + "(" + item.idNumber + ")" }}</span>
      </div>
      <div class="yyqr-choose-nodata" v-show="list.length == 0">
        <img
          src="../../assets/img/appoint/zanwujiuzhenren.png"
          width="100%"
          height="100%"
          alt=""
        />
      </div>
      <div class="yyqr-choose-add" @click="addPatient">
        <van-icon name="plus" />添加就诊人
      </div>
    </van-popup>
    <div class="checked-info-item-radio">
      <van-radio-group
        v-model="collectType"
        class="radioGroup"
        direction="horizontal"
      >
        <van-radio :name="0" icon-size="18px" checked-color="#00D48A">
          <template #icon="props">
            <i :class="props.checked ? 'activeIcon' : 'inactiveIcon'"></i>
          </template>
          <b style="color: #990000">标本单采</b>
        </van-radio>
      </van-radio-group>
    </div>
    <div class="promise-item">
      <van-cell-group>
        <van-cell v-if="this.$route.query.personType == 1">
          <van-field
            v-model="inpDept"
            label="所在科室"
            label-width="2rem"
            input-align="right"
            placeholder="请填写科室名称"
          />
        </van-cell>
        <van-cell v-if="this.$route.query.personType == 1">
          <van-field
            v-model="inpNo"
            label="住院号"
            label-width="2rem"
            input-align="right"
            placeholder="请填写住院号"
          />
        </van-cell>
      </van-cell-group>
    </div>
    <div class="address-list">
      <div class="address-content">
        <div class="address-title">新型冠状病毒核酸检测</div>
        <div class="address-subtitle">河南省第二人民医院本部</div>
        <div class="address-text">地址:河南省新郑龙湖镇双湖大道中段</div>
        <div class="address-notices">注意:如需医保支付,请前往医院现场开单</div>
      </div>
      <div class="address-radio">
        <van-radio
          v-model="chooseAddress"
          :name="0"
          checked-color="#00D48A"
        ></van-radio>
      </div>
    </div>
    <!-- <div class="address-list disabled">
        <div class="address-content">
          <div class="address-title">新型冠状病毒核酸检测</div>
          <div class="address-subtitle">祥和路社区卫生服务中心</div>
          <div class="address-text">
            地址:新郑市祥和路新郑十七里河省级湿地公园
          </div>
          <div class="address-notices">
            注意:如需医保支付,请前往医院现场开单
          </div>
        </div>
        <div class="address-radio">
          <van-radio
            v-model="chooseAddress"
            disabled
            :name="1"
            checked-color="#00D48A"
          ></van-radio>
        </div>
      </div> -->
    <div class="service-notices">
      <p>服务须知：</p>
      <p>
        1.该服务主要是用于社会人员核酸检测。有流行病学史或伴有发热及呼吸道症状的患者请勿使用线上医疗服
        务，务必到医院发热门诊就诊。
      </p>
      <p>2.下单取样流程：</p>
      <p>手机下单缴费(暂不支持医保)后，直接前往指定核酸采样点取样。</p>
      <p>
        3.结果查询：电子报告可在“检验报告”中查看，纸质报告可持身份证在急诊楼东侧检验报告自助机打印获取。
      </p>
      <p>
        4.发票获取：缴费完成后，电子发票可在“个人中心-电子发票”中查看下载，纸质发票可前往医院自助机进行打印。
      </p>
      <p>
        5.退费：退费：缴费成功后如有特殊原因不能前往检验，请在“诊疗服务-核酸缴费”中申请退款。
      </p>
    </div>
    <div class="nextStep">
      <van-button
        block
        type="info"
        class="submit-btn"
        native-type="submit"
        @click="routerPush"
        >下一步</van-button
      >
    </div>
    <van-dialog
      class="dialog"
      v-model="overflowShow1"
      title="温馨提示"
      :show-cancel-button="false"
      :show-confirm-button="false"
      :overlayStyle="{ backgroundColor: 'rgba(0,0,0,0.1)' }"
    >
      <template #title>
        <h3 class="dialog-title">温馨提示</h3>
      </template>
      <p>暂无就诊人信息，请添加就诊人</p>
      <van-button
        class="cancle-btn"
        to="/treatmentServices"
        v-if="$route.query.personType == 0"
        >取消</van-button
      >
      <van-button
        class="cancle-btn"
        @click="overflowShow1 = false"
        v-if="$route.query.personType == 1"
        >关闭</van-button
      >
      <van-button type="primary" class="confirm-btn" @click="addPatient"
        >确认</van-button
      >
    </van-dialog>
    <van-dialog
      class="dialog"
      v-model="overflowShow"
      title="温馨提示"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #title>
        <h3 class="dialog-title">温馨提示</h3>
      </template>
      <p>
        {{ content }}
      </p>
      <van-button type="primary" class="confirm-btn" to="/treatmentServices"
        >确认</van-button
      >
    </van-dialog>
  </div>
</template>

<script>
import { Notify } from "vant";
export default {
  name: "nucleicAcid",
  data() {
    return {
      showPopup: false, // 弹出层
      list: [],
      personId: "",
      chooseItem: "",
      personType: 0,
      collectType: 0,
      chooseAddress: 0,
      overflowShow: false,
      overflowShow1: false,
      content: "",
      inpDept: "", // 陪护人员填写所在科室
      inpNo: "", // 陪护人员填写住院号
    };
  },
  created() {
    this.getAliPayCode();
  },
  methods: {
    /**
     * 判断是否有就诊人
     */
    isHasPatient() {
      if (!this.chooseItem) {
        this.overflowShow1 = true;
        return false;
      }
      return true;
    },
    /**
     * 获取核酸检测功能开放状态
     */
    getState() {
      this.$http
        .get("/api/search/state", {
          type: this.$route.query.personType,
        })
        .then((result) => {
          this.overflowShow = !result.data.state;
          if (result.data.state) {
            this.isHasPatient();
          }
          this.content = result.data.content;
          return;
        });
    },
    /**
     * 获取微信公众平台的code
     */
    getAliPayCode() {
      // 判断是否有微信code
      let sessionCode = sessionStorage.getItem("AliPayCode");
      if (!sessionCode) {
        if (this.$route.query.personType == 0) {
          // 院外人员核酸
          // 没有则进行授权
          const redirectUrl = `${domain}index.html%23%2FnucleicAcid?personType=0`;
          this.code = this.getUrlCode().code; // 截取auth_code用于获取openid
          if (this.code == null || this.code === "" || this.code == undefined) {
            // 如果没有code，则去请求
            window.location.replace(
              `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.APPID}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect`
            );
          } else {
            sessionStorage.setItem("AliPayCode", this.code);
            // 获取openid
            this.getOpenID(this.code);
          }
        } else if (this.$route.query.personType == 1) {
          // 住院陪护人员核酸
          // 没有则进行授权
          const redirectUrl = `${domain}index.html%23%2FnucleicAcid?personType=1`;
          this.code = this.getUrlCode().code; // 截取auth_code用于获取openid
          if (this.code == null || this.code === "" || this.code == undefined) {
            // 如果没有code，则去请求
            window.location.replace(
              `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.APPID}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect`
            );
          } else {
            sessionStorage.setItem("AliPayCode", this.code);
            // 获取openid
            this.getOpenID(this.code);
          }
        }
      } else {
        this.getOpenID(sessionCode);
      }
    },
    /**
     * 根据code获取openid
     */
    getOpenID(code) {
      // 判断是否有openid
      if (sessionStorage.getItem("openid")) {
        this.getList();
        return;
      }
      let postData = {
        code: code,
      };
      this.$http
        .post("/api/login/wx", postData)
        .then((res) => {
          sessionStorage.setItem("user", JSON.stringify(res.data));
          sessionStorage.setItem("userid", res.data.id);
          sessionStorage.setItem("openid", res.data.userId);
          this.getList();
          this.$store.commit("hideLoading");
        })
        .catch((err) => {
          this.$store.commit("hideLoading");
          this.$toast.fail("发生错误，请关闭页面之后重新授权...");
          console.log(err);
          // 发生错误说明可能是code过期，或者session丢失，重置session重新鉴权
          sessionStorage.setItem("AliPayCode", "");
          sessionStorage.setItem("userid", "");
        });
    },
    // 获取url中code
    getUrlCode() {
      let url = location.href;
      var s = url.indexOf("?");
      var t = url.substring(s + 1);
      let strs = t.split("&");
      let theRequest = new Object();
      for (let i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
      }
      return theRequest;
    },
    // 点击就诊人
    openChooise() {
      this.showPopup = true;
    },
    // 添加就诊人
    addPatient() {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.push("/addCardOne");
    },
    /**
     * 选择就诊人
     */
    chooseJZPerson(item) {
      sessionStorage.setItem("CurrentJZPerson", JSON.stringify(item));
      this.chooseItem = item;
      this.personId = item.id;
      this.showPopup = false;
    },
    /**
     * 获取就诊人信息的方法
     */
    getList() {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        type: 1,
      };
      this.$http
        .get("/api/search/cardlist", postData)
        .then((res) => {
          if (res.status === 200) {
            this.list = res.data;
            const CurrentJZPerson = JSON.parse(
              sessionStorage.getItem("CurrentJZPerson")
            );
            if (!CurrentJZPerson) {
              if (this.list.length > 0) {
                this.personId = this.list[0].id;
                for (let i = 0; i < this.list.length; i++) {
                  if (i == 0) {
                    this.chooseItem = this.list[i];
                  }
                  if (this.list[i].deafult) {
                    this.chooseItem = this.list[i];
                  }
                }
                if (!this.chooseItem.deafult) {
                  this.chooseItem.deafult = true;
                  this.list[0].deafult = true;
                }
                sessionStorage.setItem(
                  "CurrentJZPerson",
                  JSON.stringify(this.chooseItem)
                );
              }
            } else {
              this.personId = CurrentJZPerson.id;
              this.chooseItem = CurrentJZPerson;
            }
          }
          this.getState();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    routerPush() {
      if (!this.isHasPatient()) {
        return;
      }
      if (this.collectType === "") {
        Notify({
          message: "请先选择检测类型！",
          type: "warning",
        });
      } else {
        if (this.$route.query.personType == 0) {
          // 院外人员核酸
          this.$router.push({
            path: "/enterPromise",
            query: {
              addressType: this.chooseAddress,
              collectType: this.collectType,
              personType: this.personType,
              patId: this.chooseItem.patId,
              healthId: this.chooseItem.healthId,
            },
          });
        } else if (this.$route.query.personType == 1) {
          /**
           * 住院陪护人员核酸
           */
          if (this.inpDept === "") {
            Notify({ type: "warning", message: "请填写科室名称！" });
            return;
          }
          if (this.inpNo === "") {
            Notify({ type: "warning", message: "请填写住院号！" });
            return;
          }
          // 是陪护人员
          this.$router.push({
            path: "/enterPromise",
            query: {
              addressType: this.chooseAddress,
              collectType: this.collectType,
              personType: this.$route.query.personType,
              inpDept: this.inpDept,
              inpNo: this.inpNo,
              patId: this.chooseItem.patId,
              healthId: this.chooseItem.healthId,
            },
          });
        }
      }
    },
    /**
     * 根据不同的personType修改当前页面的title
     */
    changeDocumentTitle() {
      if (this.$route.query.personType == 0) {
        document.title = "核酸检测";
      } else if (this.$route.query.personType == 1) {
        document.title = "住院陪护核酸检测";
      }
    },
  },
  mounted() {
    this.getList();
    this.changeDocumentTitle();
  },
};
</script>

<style scoped>
.body {
  background-color: #f8f9f9;
}
.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}
.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: -2px;
  left: -2px;
}

.yyqr-choose-nodata {
  font-size: 0.4rem;
  padding: 8px;
  color: #afafaf;
}
.checked-info-item-radio {
  width: 10rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: #fff;
  height: 80px;
  line-height: 80px;
  margin: auto;
  font-size: 0.4rem;
  /* background-color: pink; */
  /*border: 1px solid #D3D3D6;*/
  /*border-radius: 5px;*/
}

.checked-info-item-radio .van-radio {
  margin: 0.2rem 0.3rem;
}
.radioGroup {
  height: 80px;
  font-size: 0.42rem !important;
  display: inline-flex;
}

.activeIcon {
  display: inline-block;
  height: 15px;
  width: 15px;
  border: 1px solid #03d58e;
  border-radius: 50%;
}
.activeIcon:after {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #03d58e;
  position: relative;
  left: 1px;
  top: 1px;
}
.inactiveIcon {
  display: inline-block;
  height: 15px;
  width: 15px;
  border: 1px solid #a8a5a6;
  border-radius: 50%;
}
.address-list {
  width: 9rem;
  margin: 10px auto;
  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
}
.address-list.disabled {
  background-color: #ebedf0;
  -webkit-box-shadow: #fff 0px 0px 10px;
  -moz-box-shadow: #fff 0px 0px 10px;
  box-shadow: #fff 0px 0px 10px;
}
.address-content {
  display: inline-block;
  width: 8rem;
  height: 100%;
  text-align: left;
  box-sizing: border-box;
  padding: 0.3rem;
  font-size: 0.35rem;
  border-right: 1px solid #e6e6e6;
}
.address-content .address-title {
  font-size: 0.4rem;
  line-height: 0.8rem;
}
.address-content .address-subtitle {
  font-size: 0.4rem;
  line-height: 0.8rem;
  color: #990000;
}
.address-content .address-text {
  line-height: 0.6rem;
}
.address-content .address-notices {
  color: #1d9ecf;
}
.address-radio {
  display: inline-flex;
  width: 1rem;
  height: 100%;
  line-height: 1rem;
  justify-content: center;
}
.service-notices {
  width: 9rem;
  margin: 10px auto;
  padding: 0.3rem;
  border-radius: 5px;
  text-align: left;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.35rem;
  line-height: 0.55rem;
  background-color: #fff;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
  margin-bottom: 80px;
}
.submit-btn {
  width: 9rem;
  margin: 10px auto;
  border-radius: 5px;
  border: none;
  background-image: linear-gradient(to right, #57fdc4, #00d48b);
}
.nextStep {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  width: 100vw;
}

.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}

.yyqr-choose-jzk {
  padding: 8px;
  font-size: 0.4rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #faf7fa;
}

.yyqr-choose-jzk .checked {
  color: #03d28c;
}

.yyqr-choose-jzk img {
  height: 0.3rem;
  width: 0.3rem;
}

.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: 2px;
  left: -2px;
}

.yyqr-choose-nodata {
  font-size: 0.4rem;
  padding: 8px;
  color: #afafaf;
}
.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: -2px;
  left: -2px;
}
/deep/ .van-popup__close-icon--top-right {
  top: 6px;
}
.dialog {
  padding: 0.5rem;
  padding-top: 0;
  line-height: 0.8rem;
  box-sizing: border-box;
}

.dialog div {
  text-align: left;
}

.dialog p {
  text-indent: 0.8rem;
  text-align: left;
}

.confirm-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-top: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}
.cancle-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}
.dialog-title {
  margin-bottom: 0.3rem;
}
.promise-item {
  margin: auto;
  font-size: 0.4rem;
  text-align: left;
  border: 1px solid #ffffffff;
  border-radius: 10px;
  background-color: #ffffffff;
  padding-left: 10px;
}
.promise-item .van-cell {
  padding: 5px 16px;
}
</style>
